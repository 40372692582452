<template>
  <div class="container-md col-lg-10 col-md-10 contact-user-list">
    <LoadingOverlay v-if="isCustomerFetch" />
    <div v-else>
      <h3 class="text-primary m-1 pt-1">
        Recruiter List
        <span class="nowrap" style="font-size: 20px"
          >({{ getAllTeamMembers.length || "0" }} Record{{
            getAllTeamMembers.length > 1 ? "s" : ""
          }})</span
        >
      </h3>

      <CDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="teamMembers"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        pagination
        class="p-2 border"
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              @click="editTeamMember(item, index)"
              v-c-tooltip="'Edit'"
              ><i class="far fa-edit"></i
            ></CButton>
          </td>
        </template>
      </CDataTable>
      <EditRecruiter
        v-if="contactUserEditModal.isShowPopup"
        :isShowPopup="contactUserEditModal.isShowPopup"
        :teamMemberDetail="contactUserEditModal.teamMemberDetail"
        @modalCallBack="contactUserEditModalCallBack"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditContactUser from "@/containers/ContactUser/EditContactUser";
import EditRecruiter from "@/pages/TeamMembers/EditRecruiter";
export default {
  components: {
    EditContactUser,
    EditRecruiter,
  },
  data() {
    return {
      limit: 10,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        { key: "username", _style: "width:10%" },
        { key: "email", _style: "width:12%" },
        { key: "team", _style: "width:10%" },
        { key: "team_level", _style: "width:10%" },
        { key: "recruitment_type", _style: "width:10%" },
        { key: "action", sorter: false, filter: false, _style: "width:5%" },
      ],
      contactUserEditModal: {
        isShowPopup: false,
        teamMemberDetail: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "contactUsers",
      "isCustomerFetch",
      "getUser",
      "getAllTeamMembers",
      "getCustomerId",
      "getOrgID",
    ]),
    teamMembers() {
      return (
        (this.getAllTeamMembers &&
          this.getAllTeamMembers.length &&
          this.getAllTeamMembers
            .map((val) => ({
              username: `${val.user?.first_name || ""} ${
                val.user?.surname || ""
              }`,
              team_id: val.team?.id,
              team_level_id: val.team_level?.id,
              recruitment_type_id: val.recruitment_type?.id,
              user_id: val.user?.id,
              //
              first_name: val.user?.first_name || "--",
              surname: val.user?.surname || "--",
              email: val.user?.email || "--",
              team: val.team?.team_name || "--",
              team_level: val.team_level?.level_name || "--",
              recruitment_type: val.recruitment_type?.type_name || "--",
            }))
            .reverse()) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchContactUser",
      "fetchUser",
      "updateCustomerUser",
      "updateTeamMember",
      "getTeamMembers",
    ]),
    editTeamMember(item, index) {
      this.tooltipHide();
      let teamMemberData = this.getAllTeamMembers[
        this.getAllTeamMembers.findIndex((e) => e.user.id === item.user_id)
      ];
      this.contactUserEditModal.isShowPopup = true;
      this.contactUserEditModal.teamMemberDetail = teamMemberData;
    },
    contactUserEditModalCallBack(action, payload) {
      if (action === false) {
        this.contactUserEditModal.isShowPopup = false;
      } else if (action === true && payload) {
        this.updateTeamMember(payload).then((res) => {
          this.contactUserEditModal.isShowPopup = false;
          this.getTeamMembers({
            customer_id: this.getCustomerId,
            organisation_id: this.getOrgID,
          });
        });
      }
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
  },
  mounted() {
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      this.getTeamMembers({
        customer_id: this.getCustomerId,
        organisation_id: this.getOrgID,
      });
    });
  },
};
</script>
