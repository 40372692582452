<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit Recruiter</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Update</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <ValidationObserver ref="editTeamMember" v-slot="{ handleSubmit }">
          <form id="editTeamMember" @submit.prevent="handleSubmit(onSubmit)">
            <CRow v-if="isFetchingTeamMember">
              <CCol lg="6">
                <CRow name="customer_user_type" class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Team</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="team_id"
                        :value="user.team_id"
                        :options="
                          options && options['team_names']
                            ? options['team_names']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="customer_user_type" class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Recruitment Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="recruitment_type_id"
                        :value="user.recruitment_type_id"
                        :options="
                          options && options['recruitment_types']
                            ? options['recruitment_types']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>

              <CCol lg="6">
                <CRow name="customer_user_type" class="mb-3">
                  <label class="col-lg-12 col-md-12 required">Team Level</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="team_level_id"
                        :value="user.team_level_id"
                        :options="
                          options && options['team_level']
                            ? options['team_level']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="first_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12">First Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_name"
                        :value="user.first_name"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="surname" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Last Name</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="surname"
                        :value="user.surname"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import { deepClone, isEmptyObjectCheck } from "@/helpers/helper";

import { extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    teamMemberDetail: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
  },
  data() {
    return {
      user: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "userTypes",
      "getTeamList",
      "getTeamLevel",
      "getRecruitmentTypes",
      "dialingCodeWithCountryCode",
      "contactType",
      "isFetchingFilter",
      "getCustomerId",
      "getOrgID",
    ]),
    options() {
      return {
        dialingCode: this.dialingCodeWithCountryCode || [],
        contact_type: this.contactType || [],
        team_names: this.getTeamList || [],
        team_level: this.getTeamLevel || [],
        recruitment_types: this.getRecruitmentTypes || [],
      };
    },
    isFetchingTeamMember() {
      return true;
    },
  },
  methods: {
    ...mapActions([
      "initContactFetch",
      "showToast",
      "getTeamDetails",
      "getTeamLevels",
      "getRecruitmentType",
      "fetchContactType",
      "initFetchContactInfoOptions",
      "updateTeamMember",
    ]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.user, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
    },
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.user, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.editTeamMember.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        user_id: this.user?.user_id,
        first_name: this.user?.first_name,
        surname: this.user?.surname,
        team_id: this.user?.team_id?.code,
        team_level_id: this.user?.team_level_id?.code,
        recruitment_type_id: this.user?.recruitment_type_id?.code,
      };
      this.modalCallBack(true, finalPayload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    const userData = this.teamMemberDetail;
    this.user = {
      team_id: userData?.team
        ? {
            code: userData?.team?.id,
            label: userData?.team?.team_name,
          }
        : null,
      team_level_id: userData?.team_level
        ? {
            code: userData?.team_level?.id,
            label: userData?.team_level?.level_name,
          }
        : null,
      recruitment_type_id: userData?.recruitment_type
        ? {
            code: userData?.recruitment_type?.id,
            label: userData?.recruitment_type?.type_name,
          }
        : null,
      user_id: userData?.user?.id,
      //
      first_name: userData?.user?.first_name || null,
      surname: userData?.user?.surname || null,
    };
    this.initContactFetch();
    this.getTeamLevels();
    this.getRecruitmentType();
    this.fetchContactType();
    this.initFetchContactInfoOptions();
    this.getTeamDetails({
      customer_id: this.getCustomerId,
      organisation_id: this.getOrgID,
    });
  },
};
</script>
